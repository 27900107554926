import { Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { useWhoamiSuspense } from '../../api/generated.ts';
import { PisBadge } from '../../pis/PisBadge.tsx';
import { DataFlowCard } from './DataFlowCard.tsx';

export function PisCard() {
  const { t } = useTranslation();
  const { data: whoami } = useWhoamiSuspense();
  return (
    <DataFlowCard gap={1}>
      {whoami.pis && <PisBadge pis={whoami.pis.type} />}
      <Typography level="title-sm" textColor="text.secondary">
        {t('practice-data')}
      </Typography>
    </DataFlowCard>
  );
}
