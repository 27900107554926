import { Button, Divider, Stack } from '@healthinal/ui';
import { createFileRoute } from '@tanstack/react-router';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadDataProjectSuspense } from '../../api/generated.ts';
import { AuthDataProjectPanel } from '../../auth/AuthDataProjectPanel.tsx';
import { Permissions } from '../../data-projects/Permissions.tsx';
import { useSetGrants } from '../../data-projects/useSetGrants.ts';
import { useHealthcareProviderId } from '../../user/useHealthcareProviderId.ts';
import { AuthorizationColumn, LeftAuthorizationColumn } from '../authorization.tsx';
import { validateRequiredSearchParams } from './-utils.ts';

export interface Search {
  client_id: string;
  redirect_uri: string;
  state?: string;
}

export const Route = createFileRoute('/authorization/grant')({
  component: Grant,
  validateSearch: (search: Record<string, unknown>): Search => {
    validateRequiredSearchParams(search, 'client_id', 'redirect_uri');
    return {
      client_id: String(search.client_id),
      redirect_uri: String(search.redirect_uri),
      state: search.state ? String(search.state) : undefined,
    };
  },
});

function Grant() {
  const { t } = useTranslation();
  const search = Route.useSearch();
  const healthcareProviderId = useHealthcareProviderId();
  const { data: dataProject } = useReadDataProjectSuspense(search.client_id, {
    query: {
      interpretError: (error) => {
        if (isAxiosError(error) && (error.response?.status === 400 || error.response?.status === 404)) {
          return {
            title: t('authorization.error.invalid-request.title'),
            description: t('authorization.error.invalid-request.description'),
            details: `Invalid query parameter 'client_id' (${error.response.status})`,
            shouldBeReported: false,
          };
        }
        return undefined;
      },
    },
  });

  const { grantedScopeRequestIds, setGrantedScopeRequestIds, mutateAsync, isPending } = useSetGrants(dataProject);

  const [isRedirecting, setIsRedirecting] = useState(false);
  const isSubmitting = isPending || isRedirecting;

  const cancel = () => {
    window.location.href = search.redirect_uri;
  };

  const submit = async () => {
    await mutateAsync();
    setIsRedirecting(true);
    redirectToAuthorizeEndpoint(healthcareProviderId, search);
  };

  return (
    <Stack direction="row" flex={1}>
      <LeftAuthorizationColumn>
        <AuthDataProjectPanel
          dataProject={dataProject}
          title={t('authorization.grant.title')}
          prompt={t('authorization.grant.prompt', { dataProject: dataProject.name })}
          hint={t('authorization.grant.hint')}
        />
      </LeftAuthorizationColumn>
      <Divider orientation="vertical" />
      <AuthorizationColumn>
        <Permissions
          requests={dataProject.scopes}
          grantedIds={grantedScopeRequestIds}
          onGrantedIdsChange={setGrantedScopeRequestIds}
          disabled={isSubmitting}
        />
        <Stack direction="row" gap={2} mt="auto">
          <Button fullWidth onClick={cancel} disabled={isSubmitting} variant="soft" color="neutral">
            {t('cancel')}
          </Button>
          <Button fullWidth onClick={submit} loading={isSubmitting}>
            {t('authorization.grant.action')}
          </Button>
        </Stack>
      </AuthorizationColumn>
    </Stack>
  );
}

function redirectToAuthorizeEndpoint(healthcareProviderId: string, { client_id, state, redirect_uri }: Search) {
  const redirect = new URL('/portal-api/data-projects/authorize', window.location.href);
  redirect.searchParams.append('client_id', client_id);
  redirect.searchParams.append('redirect_uri', redirect_uri);
  redirect.searchParams.append('healthcareProviderId', healthcareProviderId);
  if (state) {
    redirect.searchParams.append('state', state);
  }
  window.location.href = redirect.href;
}
