import { Stack } from '@healthinal/ui';
import { captureException } from '@sentry/react';
import { createRootRoute, Outlet } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { Suspense } from 'react';
import { SplashScreen } from '../common/components/SplashScreen.tsx';
import { ErrorBoundary } from '../errors/ErrorBoundary.tsx';
import { ErrorView } from '../errors/ErrorView.tsx';
import { interpretError } from '../errors/interpretError.ts';

export const Route = createRootRoute({
  component: Root,
  // All errors should be handled by the ErrorBoundary above, unless rendering the root route itself goes wrong.
  // Letting it bubble outside the router is not possible at this time: https://github.com/TanStack/router/issues/1181
  onCatch: (error) => captureException(error),
  errorComponent: ({ error }) => <ErrorView {...interpretError(error)} />,
});

function Root() {
  return (
    <Stack minHeight="100vh">
      <Suspense fallback={<SplashScreen />}>
        <ErrorBoundary isRoot>
          <Outlet />
        </ErrorBoundary>
      </Suspense>
      <TanStackRouterDevtools />
    </Stack>
  );
}
