import { Button, Icons, Stack, styled, Typography } from '@healthinal/ui';
import { isSameDay } from 'date-fns';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDataProjectAccessOfHealthcareProviderSuspenseInfinite } from '../api/generated.ts';
import { StickyTableRow, StyledTable, tableRowHeight } from '../common/components/StyledTable.tsx';
import { formatDate } from '../i18n/date.ts';
import { theme } from '../theme.ts';
import { useHealthcareProviderId } from '../user/useHealthcareProviderId.ts';
import { AccessLogRow } from './AccessLogRow.tsx';

const totalColSpan = 5;
const thresholdDays = 180;

export function AccessLogTable() {
  const { t } = useTranslation();
  const healthcareProviderId = useHealthcareProviderId();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useDataProjectAccessOfHealthcareProviderSuspenseInfinite(
      {
        healthcareProviderId,
        olderThan: '',
        pageSize: 20,
      },
      {
        query: {
          initialPageParam: new Date().toISOString(),
          getNextPageParam: (lastPage) => lastPage.next,
        },
      },
    );

  const requestContexts = useMemo(() => data.pages.flatMap((page) => page.result) ?? [], [data.pages]);

  if (requestContexts.length === 0) {
    return (
      <Stack alignItems="center" paddingY={5} gap={1}>
        <Typography level="title-lg" marginBottom={1}>
          {t('access-log.empty.title')}
        </Typography>
        <Typography level="body-md" textColor="text.secondary">
          {t('access-log.empty.description', { thresholdDays })}
        </Typography>
        <Typography level="body-sm">{t('access-log.threshold-info', { thresholdDays })}</Typography>
      </Stack>
    );
  }

  return (
    <StyledTable>
      <thead>
        <StickyTableRow>
          <th style={{ width: 64 }}>{t('time')}</th>
          <th style={{ width: 200 }}>{t('data-project')}</th>
          <th>{t('description')}</th>
          <th style={{ width: 240 }}>{t('user')}</th>
          <th style={{ width: 80 }}>{t('pid')}</th>
        </StickyTableRow>
      </thead>
      <tbody>
        {requestContexts.map((requestContext, index) => (
          <Fragment key={requestContext.requestContextId}>
            {(index === 0 ||
              !isSameDay(requestContexts[index - 1].oldestTimestamp, requestContext.oldestTimestamp)) && (
              <DayHeaderTableRow>
                <td colSpan={totalColSpan}>{formatDate(requestContext.oldestTimestamp)}</td>
              </DayHeaderTableRow>
            )}
            <AccessLogRow requestContext={requestContext} />
          </Fragment>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={totalColSpan} style={{ textAlign: 'center' }}>
            {hasNextPage ? (
              <Button
                variant="plain"
                color="neutral"
                size="sm"
                startDecorator={<Icons.ExpandMore />}
                onClick={() => fetchNextPage()}
                loading={isFetchingNextPage}>
                {t('access-log.next-page')}
              </Button>
            ) : (
              <Typography level="body-sm" marginY={2}>
                {t('access-log.end-reached')} {t('access-log.threshold-info', { thresholdDays })}
              </Typography>
            )}
          </td>
        </tr>
      </tfoot>
    </StyledTable>
  );
}

const DayHeaderTableRow = styled(StickyTableRow)({
  background: `color-mix(in srgb, ${theme.vars.palette.background.body}, ${theme.vars.palette.background.surface})`,
  top: tableRowHeight,
  ...theme.typography['title-sm'],
  color: theme.vars.palette.text.secondary,
});
