import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Textarea,
} from '@healthinal/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  CreatePidBlockDto,
  getReadBlockedPidsQueryKey,
  PidBlockDto,
  useCreateBlockedPid,
  useUpdateBlockedPid,
} from '../../api/generated.ts';
import { FormErrorMessage } from '../../common/components/FormErrorMessage.tsx';
import { ReactHookFormControl } from '../../common/components/ReactHookFormControl.tsx';
import { useHealthcareProviderId } from '../../user/useHealthcareProviderId.ts';

const MAX_PID_LENGTH = 100;
const MAX_PID_COMMENT_LENGTH = 1000;

interface PidBlockModalProps {
  pidBlock?: PidBlockDto;
  open: boolean;
  close: () => void;
  isExistingPidBlock: (pid: string) => boolean;
}

export function PidBlockModal({ pidBlock, open, close, isExistingPidBlock }: PidBlockModalProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const healthcareProviderId = useHealthcareProviderId();

  const {
    register,
    control,
    handleSubmit,
    reset: resetForm,
  } = useForm<CreatePidBlockDto>({
    values: pidBlock,
  });

  const closeModal = () => {
    void queryClient.invalidateQueries({
      queryKey: getReadBlockedPidsQueryKey(healthcareProviderId),
    });
    resetForm();
    resetCreateQuery();
    resetUpdateQuery();
    close();
  };

  const {
    mutateAsync: createPidBlockMutate,
    isPending: isCreatePending,
    error: createError,
    reset: resetCreateQuery,
  } = useCreateBlockedPid({ mutation: { onSuccess: closeModal } });
  const {
    mutateAsync: updatePidBlockMutate,
    isPending: isUpdatePending,
    error: updateError,
    reset: resetUpdateQuery,
  } = useUpdateBlockedPid({ mutation: { onSuccess: closeModal } });

  const submit = async (data: CreatePidBlockDto) => {
    if (pidBlock) {
      await updatePidBlockMutate({
        healthcareProviderId: healthcareProviderId,
        pid: pidBlock.pid,
        data: { comment: data.comment },
      });
    } else {
      await createPidBlockMutate({
        healthcareProviderId: healthcareProviderId,
        data: data,
      });
    }
  };

  return (
    <Modal open={open} onClose={() => closeModal()}>
      <ModalDialog minWidth="sm" maxWidth="sm">
        <form onSubmit={handleSubmit(submit)}>
          <DialogTitle>
            {!pidBlock ? t('settings.block-list.modals.create-title') : t('settings.block-list.modals.update-title')}
            <ModalClose />
          </DialogTitle>
          <DialogContent>
            <ReactHookFormControl name="pid" label={t('pid')} control={control} required disabled={!!pidBlock}>
              <Input
                {...register('pid', {
                  required: { value: true, message: t('settings.block-list.error.pid-blank') },
                  maxLength: {
                    value: MAX_PID_LENGTH,
                    message: t('settings.block-list.error.pid-max-length', { pidLength: MAX_PID_LENGTH }),
                  },
                  validate: (value) => !isExistingPidBlock(value) || t('settings.block-list.error.pid-duplicate'),
                })}
              />
            </ReactHookFormControl>
            <ReactHookFormControl
              name="comment"
              label={t('settings.block-list.pid-comment')}
              control={control}
              helperText={t('settings.block-list.pid-comment-description')}>
              <Textarea
                minRows={3}
                maxRows={12}
                {...register('comment', {
                  maxLength: {
                    value: MAX_PID_COMMENT_LENGTH,
                    message: t('settings.block-list.error.pid-comment-length', {
                      pidCommentLength: MAX_PID_COMMENT_LENGTH,
                    }),
                  },
                })}
              />
            </ReactHookFormControl>
          </DialogContent>
          <FormErrorMessage errorMessage={createError?.message ?? updateError?.message} />
          <DialogActions>
            <Button variant="solid" type="submit" loading={isCreatePending || isUpdatePending}>
              {t('save')}
            </Button>
            <Button variant="plain" onClick={closeModal} disabled={isCreatePending || isUpdatePending}>
              {t('cancel')}
            </Button>
          </DialogActions>
        </form>
      </ModalDialog>
    </Modal>
  );
}
