import { Alert, Icons, Stack, styled, Typography } from '@healthinal/ui';
import { ErrorInterpretation } from './interpretError.ts';

interface ErrorViewProps extends ErrorInterpretation {
  sentryId?: string;
}

export function ErrorView({ title, description, details, sentryId, shouldBeReported }: ErrorViewProps) {
  return (
    <Stack maxWidth={480} alignItems="center" gap={2} margin="auto">
      <Icons.ErrorOutline color="danger" />
      <Typography level="h3" textAlign="center">
        {title}
      </Typography>
      <Typography textAlign="center">{description}</Typography>
      {details && <Details>{details}</Details>}
      {shouldBeReported && sentryId && <Typography level="body-sm">Sentry ID: {sentryId}</Typography>}
    </Stack>
  );
}

const Details = styled(Alert)`
  font-family: monospace;
  white-space: pre-line;
  width: 100%;
`;
