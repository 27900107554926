import { Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { HeurekaSpinner } from '../../common/components/HeurekaSpinner.tsx';
import { DataFlowCard } from './DataFlowCard.tsx';
import { flowAnimationInterval } from './FlowAnimation.tsx';

export function HeurekaConnectorCard() {
  const { t } = useTranslation();
  return (
    <DataFlowCard>
      <HeurekaSpinner loopInterval={flowAnimationInterval} />
      <Typography level="title-sm" textColor="text.secondary">
        {t('heureka-connector')}
      </Typography>
    </DataFlowCard>
  );
}
