import { Button, Divider, Stack, Typography } from '@healthinal/ui';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadDataProjectSuspense } from '../../api/generated.ts';
import { AuthDataProjectPanel } from '../../auth/AuthDataProjectPanel.tsx';
import { RevokeComment } from '../../data-projects/RevokeComment.tsx';
import { RevokeReasons } from '../../data-projects/RevokeReasons.tsx';
import { useRevokeGrants } from '../../data-projects/useRevokeGrants.ts';
import { AuthorizationColumn, LeftAuthorizationColumn } from '../authorization.tsx';
import { useDataProjectGrants, validateRequiredSearchParams } from './-utils.ts';

export interface Search {
  installation_id: string;
  redirect_uri: string;
}

export const Route = createFileRoute('/authorization/revoke')({
  component: Revoke,
  validateSearch: (search: Record<string, unknown>): Search => {
    validateRequiredSearchParams(search, 'installation_id', 'redirect_uri');
    return {
      installation_id: String(search.installation_id),
      redirect_uri: String(search.redirect_uri),
    };
  },
});

function Revoke() {
  const { t } = useTranslation();
  const { installation_id: dataProjectGrantId, redirect_uri: redirectUri } = Route.useSearch();

  const { data: grants } = useDataProjectGrants(dataProjectGrantId);
  const { data: dataProject } = useReadDataProjectSuspense(grants.dataProjectId);
  const { reasons, setReasons, reasonComment, setReasonComment, mutateAsync, isPending } = useRevokeGrants(
    dataProject.id,
  );

  const [isRedirecting, setIsRedirecting] = useState(false);
  const isSubmitting = isPending || isRedirecting;

  const cancel = () => {
    window.location.href = redirectUri;
  };

  const submit = async () => {
    await mutateAsync();
    setIsRedirecting(true);
    window.location.href = redirectUri;
  };

  return (
    <Stack direction="row" flex={1}>
      <LeftAuthorizationColumn>
        <AuthDataProjectPanel
          dataProject={dataProject}
          title={t('authorization.revoke.title')}
          prompt={t('authorization.revoke.prompt', { dataProject: dataProject.name })}
          hint={t('authorization.revoke.hint')}
        />
      </LeftAuthorizationColumn>
      <Divider orientation="vertical" />
      <AuthorizationColumn>
        <Stack gap={2}>
          <Typography level="body-sm">
            {t('authorization.revoke.reason.question', { dataProject: dataProject.name })}
          </Typography>
          <RevokeReasons reasons={reasons} onReasonsChange={setReasons} />
          <RevokeComment value={reasonComment} onValueChange={setReasonComment} />
        </Stack>
        <Stack direction="row" gap={2} mt="auto">
          <Button fullWidth onClick={cancel} disabled={isSubmitting} variant="soft" color="neutral">
            {t('cancel')}
          </Button>
          <Button fullWidth onClick={submit} color="danger" loading={isSubmitting}>
            {t('authorization.revoke.action')}
          </Button>
        </Stack>
      </AuthorizationColumn>
    </Stack>
  );
}
