import { Button, Divider, Stack } from '@healthinal/ui';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadDataProjectSuspense } from '../../api/generated.ts';
import { AuthDataProjectPanel } from '../../auth/AuthDataProjectPanel.tsx';
import { Permissions } from '../../data-projects/Permissions.tsx';
import { useSetGrants } from '../../data-projects/useSetGrants.ts';
import { AuthorizationColumn, LeftAuthorizationColumn } from '../authorization.tsx';
import { useDataProjectGrants, validateRequiredSearchParams } from './-utils.ts';

export interface Search {
  installation_id: string;
  redirect_uri: string;
}

export const Route = createFileRoute('/authorization/update')({
  component: Grant,
  validateSearch: (search: Record<string, unknown>): Search => {
    validateRequiredSearchParams(search, 'installation_id', 'redirect_uri');
    return {
      installation_id: String(search.installation_id),
      redirect_uri: String(search.redirect_uri),
    };
  },
});

function Grant() {
  const { t } = useTranslation();
  const { installation_id: dataProjectGrantId, redirect_uri: redirectUri } = Route.useSearch();

  const { data: grants } = useDataProjectGrants(dataProjectGrantId);
  const { data: dataProject } = useReadDataProjectSuspense(grants.dataProjectId);
  const { grantedScopeRequestIds, setGrantedScopeRequestIds, mutateAsync, isPending } = useSetGrants(dataProject);

  const [isRedirecting, setIsRedirecting] = useState(false);
  const isSubmitting = isPending || isRedirecting;

  const cancel = () => {
    window.location.href = redirectUri;
  };

  const submit = async () => {
    await mutateAsync();
    setIsRedirecting(true);
    window.location.href = redirectUri;
  };

  return (
    <Stack direction="row" flex={1}>
      <LeftAuthorizationColumn>
        <AuthDataProjectPanel
          dataProject={dataProject}
          title={t('authorization.update.title')}
          prompt={t('authorization.update.prompt', { dataProject: dataProject.name })}
        />
      </LeftAuthorizationColumn>
      <Divider orientation="vertical" />
      <AuthorizationColumn>
        <Permissions
          requests={dataProject.scopes}
          grantedIds={grantedScopeRequestIds}
          onGrantedIdsChange={setGrantedScopeRequestIds}
          disabled={isSubmitting}
        />
        <Stack direction="row" gap={2} mt="auto">
          <Button fullWidth onClick={cancel} disabled={isSubmitting} variant="soft" color="neutral">
            {t('cancel')}
          </Button>
          <Button fullWidth onClick={submit} loading={isSubmitting}>
            {t('save')}
          </Button>
        </Stack>
      </AuthorizationColumn>
    </Stack>
  );
}
