import { Stack, StackProps, styled } from '@healthinal/ui';
import { theme } from '../../theme.ts';
import { flowAnimationStyles } from './FlowAnimation.tsx';

export function DataFlowCard({ children, ...stackProps }: StackProps) {
  return (
    <AnimatedBorder>
      <Content {...stackProps}>{children}</Content>
    </AnimatedBorder>
  );
}

const AnimatedBorder = styled('div')({
  padding: '2px',
  borderRadius: `calc(${theme.vars.radius.md} + 2px)`,
  ...flowAnimationStyles,
});

const Content = styled(Stack)({
  background: theme.vars.palette.background.surface,
  padding: '16px 24px',
  borderRadius: theme.vars.radius.md,
  alignItems: 'center',
});
