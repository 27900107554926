import { Dropdown, Menu, MenuItem } from '@healthinal/ui';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWhoamiSuspense } from '../../api/generated.ts';
import { HubspotFormDialog } from './HubspotForm.tsx';

const SUPPORT_FORM_ID = '42130ece-fc9b-4629-b4d2-3247840770e4';
const FEEDBACK_FORM_ID = '74e6dceb-a3a6-438d-bc52-a2ff587823ff';

export function HelpMenu({ children }: { children: ReactNode }) {
  const { t } = useTranslation();
  const { data: whoami } = useWhoamiSuspense();

  const [openSupportForm, setOpenSupportForm] = useState(false);
  const [openFeedbackForm, setOpenFeedbackForm] = useState(false);

  const defaultFormValues = {
    email: whoami.email,
    hrka_name: whoami.name,
    hrka_hcp: whoami.organization,
  };

  return (
    <Dropdown>
      {children}
      <Menu placement="bottom-end">
        <MenuItem component="a" href="https://heureka.health/#faq" target="_blank">
          {t('frequently-asked-questions')}
        </MenuItem>
        <MenuItem onClick={() => setOpenSupportForm(true)}>{t('support')}</MenuItem>
        <MenuItem onClick={() => setOpenFeedbackForm(true)}>{t('give-feedback')}</MenuItem>
      </Menu>
      {openSupportForm && (
        <HubspotFormDialog
          onClose={() => setOpenSupportForm(false)}
          formId={SUPPORT_FORM_ID}
          formDefaultValues={defaultFormValues}
        />
      )}
      {openFeedbackForm && (
        <HubspotFormDialog
          onClose={() => setOpenFeedbackForm(false)}
          formId={FEEDBACK_FORM_ID}
          formDefaultValues={defaultFormValues}
        />
      )}
    </Dropdown>
  );
}
