import { Avatar, Dropdown, Icons, ListDivider, Menu, MenuButton, MenuItem, Stack, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { useWhoamiSuspense } from '../../api/generated.ts';

export function UserMenu() {
  const { t } = useTranslation();
  const { data } = useWhoamiSuspense();
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: Avatar }}
        slotProps={{
          root: { size: 'sm', sx: { cursor: 'pointer' }, color: 'primary' },
        }}>
        <Icons.Person size="sm" color="primary" />
      </MenuButton>
      <Menu placement="bottom-end" sx={{ width: '320px', '--ListItem-paddingX': '16px' }}>
        <MenuItem disabled sx={{ paddingY: '8px' }}>
          <Stack gap={0.5}>
            <Typography level="title-md">{data.name}</Typography>
            <Typography level="body-sm">{data.email}</Typography>
          </Stack>
        </MenuItem>
        <ListDivider />
        <MenuItem sx={{ paddingY: '12px' }} onClick={() => (window.location.href = '/portal-api/logout')}>
          <Icons.Logout />
          {t('logout')}
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}
