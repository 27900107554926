import { isAxiosError } from 'axios';
import { t } from 'i18next';
import { useGetGrantsByDataProjectGrantIdSuspense } from '../../api/generated.ts';
import { ErrorWithInterpretation } from '../../errors/interpretError.ts';

export function validateRequiredSearchParams(search: Record<string, unknown>, ...parameters: string[]) {
  for (const parameter of parameters) {
    if (!search[parameter]) {
      throw new ErrorWithInterpretation({
        title: t('authorization.error.invalid-request.title'),
        description: t('authorization.error.invalid-request.description'),
        details: `Missing query parameter '${parameter}'`,
        shouldBeReported: false,
      });
    }
  }
}

export function useDataProjectGrants(dataProjectGrantId: string) {
  return useGetGrantsByDataProjectGrantIdSuspense(dataProjectGrantId, {
    query: {
      interpretError: (error) => {
        if (isAxiosError(error)) {
          if (error.response?.status === 400 || error.response?.status === 404) {
            return {
              title: t('authorization.error.invalid-request.title'),
              description: t('authorization.error.invalid-request.description'),
              details: `Invalid query parameter 'installation_id' (${error.response.status})`,
              shouldBeReported: false,
            };
          }
          if (error.response?.status === 403) {
            return {
              title: t('authorization.error.forbidden.title'),
              description: t('authorization.error.forbidden.description'),
              shouldBeReported: false,
            };
          }
        }
        return undefined;
      },
    },
  });
}
