import { AnimationItem } from 'lottie-web';
import { useEffect, useRef } from 'react';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import animationData from './HeurekaSpinner.json';

interface HeurekaSpinnerProps {
  size?: 'md' | 'lg';
  loopInterval?: number;
}

export function HeurekaSpinner({ size = 'md', loopInterval }: HeurekaSpinnerProps) {
  const playerRef = useRef<AnimationItem>(null);

  useEffect(() => {
    if (loopInterval === undefined) return;
    const interval = setInterval(() => {
      playerRef.current?.stop();
      playerRef.current?.play();
    }, loopInterval);
    return () => clearInterval(interval);
  }, [loopInterval]);

  return (
    <Lottie
      ref={playerRef}
      loop={loopInterval === undefined}
      play
      animationData={animationData}
      style={{ width: size === 'lg' ? 160 : 64 }}
    />
  );
}
