import { Icons, Stack, Switch, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { PortalScopeRequest, PortalScopeRequestType } from '../api/generated.ts';

interface PermissionScopeProps {
  request: PortalScopeRequest;
  granted?: boolean;
  onGrantedChange?: (granted: boolean) => void;
  disabled?: boolean;
  readOnly?: boolean;
}

export function PermissionScope({ request, granted, onGrantedChange, disabled, readOnly }: PermissionScopeProps) {
  const { t } = useTranslation();
  const ResourceIcon = getResourceIcon(request.type);

  return (
    <Stack component="label" direction="row" gap={1} sx={{ cursor: request.optional ? 'pointer' : undefined }}>
      <ResourceIcon type={request.type} size="sm" />
      <Stack flex={1} gap={0.5}>
        <Typography level="title-sm">{t(`permission.read.${request.type}`)}</Typography>
        <Typography level="body-sm">{request.resourceString}</Typography>
      </Stack>
      {request.optional && !readOnly && (
        <Switch
          sx={{ alignSelf: 'flex-start' }}
          checked={granted}
          onChange={(event) => onGrantedChange?.(event.target.checked)}
          disabled={disabled}
        />
      )}
    </Stack>
  );
}

function getResourceIcon(type: PortalScopeRequestType) {
  switch (type) {
    case 'PATIENT':
      return Icons.Person;
    case 'MEDICATION_STATEMENT':
      return Icons.Vaccines;
    case 'OBSERVATION':
      return Icons.Assessment;
    case 'CONDITION':
      return Icons.Flag;
    case 'APPOINTMENT':
      return Icons.Today;
  }
}
