import {
  Alert,
  Button,
  DialogTitle,
  IconButton,
  Icons,
  Modal,
  ModalClose,
  ModalDialog,
  ModalProps,
  Stack,
  Typography,
} from '@healthinal/ui';
import { Navigate } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { PortalDataProject } from '../api/generated.ts';
import { interpretError } from '../errors/interpretError.ts';
import { RevokeComment } from './RevokeComment.tsx';
import { RevokeReasons } from './RevokeReasons.tsx';
import { useRevokeGrants } from './useRevokeGrants.ts';

interface RevokeDialogProps extends Omit<ModalProps, 'children'> {
  dataProject: PortalDataProject;
  onClose: () => void;
}

export function RevokeGrantDialog({ dataProject, onClose, ...modalProps }: RevokeDialogProps) {
  const { t } = useTranslation();

  const { reasons, setReasons, reasonComment, setReasonComment, mutate, isPending, isSuccess, isError, error, reset } =
    useRevokeGrants(dataProject.id);

  if (isSuccess) {
    return <Navigate to="/" />;
  }

  return (
    <Modal
      {...modalProps}
      onClose={(_, reason) => {
        // disable closing via escape key or backdrop click to prevent users from accidentally
        // closing the dialog and potentially losing the comment they've written
        if (reason === 'closeClick') {
          onClose();
        }
      }}>
      <ModalDialog maxWidth={480} minWidth={480}>
        <ModalClose />
        <DialogTitle>{t('authorization.revoke.title')}</DialogTitle>
        <Stack gap={2}>
          <Typography level="body-sm">
            {t('authorization.revoke.reason.question', { dataProject: dataProject.name })}
          </Typography>
          <RevokeReasons reasons={reasons} onReasonsChange={setReasons} />
          <RevokeComment value={reasonComment} onValueChange={setReasonComment} />
        </Stack>
        {isError && (
          <Alert
            color="danger"
            endDecorator={
              <IconButton onClick={reset} color="danger">
                <Icons.Close />
              </IconButton>
            }>
            {interpretError(error).description}
          </Alert>
        )}
        <Stack direction="row" gap={2} mt={3}>
          <Button fullWidth onClick={() => onClose()} disabled={isPending} variant="soft" color="neutral">
            {t('cancel')}
          </Button>
          <Button fullWidth onClick={mutate} color="danger" loading={isPending}>
            {t('authorization.revoke.action')}
          </Button>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}
