import { CssBaseline, CssVarsProvider } from '@healthinal/ui';
import { QueryClientProvider } from '@tanstack/react-query';
import { createRouter, RouterProvider } from '@tanstack/react-router';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { queryClient } from './api/queryClient.ts';
import { initSentry } from './errors/sentry.ts';
import { initI18next } from './i18n/i18next.ts';
import { routeTree } from './routes/-generated.ts';
import { theme } from './theme.ts';

initI18next();
initSentry();

const router = createRouter({ routeTree });
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </CssVarsProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
