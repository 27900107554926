import { Box, Button, Card, Chip, Icons, Stack, Typography } from '@healthinal/ui';
import { createFileRoute, Outlet, useRouter } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { useGetGrantsOfDataProjectSuspense, useReadDataProjectSuspense } from '../../../api/generated.ts';
import { TabLink } from '../../../common/components/TabLink.tsx';
import { useHealthcareProviderId } from '../../../user/useHealthcareProviderId.ts';

export const Route = createFileRoute('/_main/apps/$id')({
  component: DataProjectDetails,
});

function DataProjectDetails() {
  const { t } = useTranslation();
  const { history } = useRouter();

  const { id } = Route.useParams();
  const healthcareProviderId = useHealthcareProviderId();
  const { data: dataProject } = useReadDataProjectSuspense(id);
  const { data: grants } = useGetGrantsOfDataProjectSuspense(id, { healthcareProviderId });

  return (
    <Stack gap={5}>
      <Box>
        <Button variant="plain" color="neutral" startDecorator={<Icons.ChevronLeft />} onClick={() => history.back()}>
          {t('back')}
        </Button>
      </Box>
      <Stack direction="row" gap={3}>
        <img src={dataProject.logo} alt="" width={112} height={112} />
        <Stack gap={2}>
          <Stack>
            <Typography level="h2">{dataProject.name}</Typography>
            <Typography level="body-lg" textColor="text.tertiary">
              {dataProject.provider}
            </Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            {dataProject.categories.map((category) => (
              <Chip key={category.key}>{category.name}</Chip>
            ))}
          </Stack>
        </Stack>
      </Stack>

      {grants.dataProjectGranted && (
        <Card orientation="horizontal" sx={{ background: 'none', justifyContent: 'space-between' }}>
          <Stack direction="row" gap="24px">
            <TabLink from={Route.fullPath} to="./control-center">
              {t('control-center')}
            </TabLink>
            <TabLink from={Route.fullPath} to="./info">
              {t('information')}
            </TabLink>
          </Stack>
          {dataProject.launchUrl && (
            <Button component="a" href={dataProject.launchUrl} target={'_blank'} endDecorator={<Icons.Launch />}>
              {t('launch-application')}
            </Button>
          )}
        </Card>
      )}
      <Outlet />
    </Stack>
  );
}
