import { Button, Card, Stack, Typography } from '@healthinal/ui';
import { useTranslation } from 'react-i18next';
import { LoginOptions200Item, useLoginOptionsSuspense } from '../api/generated.ts';
import { HeurekaLogo } from '../common/components/HeurekaLogo.tsx';

export function LoginScreen() {
  const { t } = useTranslation();
  const { data: loginOptions } = useLoginOptionsSuspense();
  return (
    <>
      <Stack height={80} justifyContent="center" mb={10}>
        <HeurekaLogo height={32} />
      </Stack>
      <Card sx={{ maxWidth: 400, padding: 4, gap: 4, margin: 'auto' }}>
        <Typography level="h3" textAlign="center">
          {t('heureka-portal')}
        </Typography>
        <Typography level="body-md">{t('error.unauthenticated.description')}</Typography>
        <Stack gap={2}>
          {loginOptions.map((option) => (
            <LoginButton key={option} option={option} />
          ))}
        </Stack>
      </Card>
    </>
  );
}

interface LoginButtonProps {
  option: LoginOptions200Item;
}

function LoginButton({ option }: LoginButtonProps) {
  const { t } = useTranslation();

  const sx =
    option === LoginOptions200Item.HIN
      ? {
          background: '#f29800',
          '&:hover': {
            background: '#e08e00',
          },
        }
      : {};

  const loginUrl = new URL('/portal-api/login', window.location.href);
  loginUrl.searchParams.append('login-option', option);
  loginUrl.searchParams.append('redirectUri', window.location.href);

  return (
    <Button component="a" href={loginUrl.toString()} sx={sx}>
      {t(`login-with.${option.toLowerCase()}`)}
    </Button>
  );
}
